import ApiService from './ApiService';

const authService = {
  isAuthenticated: false,

  isAuthenticatedUser: async () => {
    try {
      // Faites une requête HTTP vers votre endpoint pour vérifier l'authentification
      const url = '/api/auth/logged/admin';
      const response = await ApiService.get(url, null, localStorage.getItem("token_auth"), localStorage.getItem("token_user"));

      if (response.status === 200) {
        authService.isAuthenticated = true;
        return {
          'auth': true,
          'user': response.user
        };
      } else {
        authService.isAuthenticated = false;
        return {
          'auth': false,
          'user': response.user
        };
      }
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'authentification', error);
      authService.isAuthenticated = false;
      return false;
    }
  },
};

export default authService;